// import { extendTheme } from "@chakra-ui/react"
// const theme = {
// //   colors: {
// //     primary: "rebeccapurple",
// //   },
//   styles: {
//     global: {
//       body: {
//         bg: "lightblue", // Replace with your desired background color
//       },
//     },
//   },
// }

// export default extendTheme(theme)

// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
const theme = {
//   styles: {
//     global: {
//       body: {
//         bg: 'purple',
//         color: 'red',
//       },
//       b: {
//         color: 'teal.500',
//         _hover: {
//           textDecoration: 'underline'
//         }
//       },
//       div: {
//         bg: 'green'
//       }
//     }
//   },
//   colors: {
//     primary: "rebeccapurple",
//   },
  fonts: {
    body: 'Montserrat, sans-serif', // Sets the font-family globally
  }
}

export default extendTheme(theme) // or extendBaseTheme
